/* index.css */
:root {
  --blue-1: #e6f4ff;
  --blue-2: #bae0ff;
  --blue-3: #91caff;
  --blue-4: #69b1ff;
  --blue-5: #4096ff;
  --blue-6: #1677ff;
  --blue-7: #0958d9;
  --blue-8: #003eb3;
  --blue-9: #002c8c;
  --blue-10: #001d66;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
