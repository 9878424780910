/* menuStyles.css */
.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item:hover {
  background-color: #1890ff; /* The default hover and selected background color */
}

.ant-menu-dark .ant-menu-item a {
  color: white !important; /* Ensure the menu item text color is white */
}

.ant-menu-dark .ant-menu-item a:hover {
  color: #40a9ff !important; /* Optional: Hover color for menu item text */
}

.ant-menu-dark a {
    color: white !important;
}
